import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import Header from './Components/Header/Header';
import { Home } from './Components/Home/Home';
import About from './Components/About/About';
import Projects from './Components/Projects/Projects';
import MobileHome from './Components/MobileHome/Home';
import MobileHeader from './Components/MobileHeader/MobileHeader'
import MobileAbout from './Components/MobileAbout/MobileAbout';
import MobileProjects from './Components/MobileProjects/MobileProjects';

function App() {
  const [curr, setCurr] = useState("Home")
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 900); 
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="App">    
    { isMobile ?<>
    <MobileHeader curr={curr} setCurr={setCurr}/>
    {curr == "Home" ? <MobileHome /> : curr == "About" ? <MobileAbout /> : curr == "Projects" ? <MobileProjects /> : <></>}
    </> :<>
      <Header curr={curr} setCurr={setCurr}/>
     {curr == "Home" ? <Home /> : curr == "About" ? <About /> : curr == "Projects" ? <Projects /> : <></>}
    </>}
  
    </div>
  );
}

export default App;
