import React from "react"
import "./Header.css"

interface HeaderProps {
    curr: string,
    setCurr: any
}

export const Header = ({curr, setCurr}: HeaderProps) => {

    return <div className="header-container">
        <span className="name">
            Hugo Heer
        </span>
        <div className="header-links">
            <button onClick={() => {
                setCurr("Home")
            }} className={curr != "Home" ? "header-buttons" : "header-buttons-selected"}>
                Home
            </button>
            <button onClick={() => {
                setCurr("About")
            }} className={curr != "About" ? "header-buttons" : "header-buttons-selected"}>
                About
            </button>

            <button onClick={() => {
                setCurr("Projects")
            }} className={curr != "Projects" ? "header-buttons" : "header-buttons-selected"}>
                Projects
            </button>            
        </div>
    </div>
}

export default Header