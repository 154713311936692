import React, { useState } from "react" 
import "./About.css"
import react from "../../assets/react.png"
import solidity from "../../assets/solidity.png"
import sql from "../../assets/sql.png"
import node from "../../assets/node.png"
import hyperledger from "../../assets/hyperledger.svg"

export const About = () => {
    
    return <div className="about-container">
        <div className="about-side">
        <BoxAbout title="Frontend developer"
            description={["Maintain the dashboard","Design and develop new features to improve user experience"]}
            date="September 2020 - January 2021"
            loc="Ekee"
            images={[react]}
            />
            <BoxAbout title="Blockchain consultant "
            description={["Design and develop blockchain oriented solutions for companies", "Create a platform to facilitate NFT creation and management", "Create and give trainings around blockchain technology"]}
            date="February 2022 - May 2024"
            loc="BearingPoint"
            images={[react, solidity, sql, node]}

            />
            <BoxAbout title="Blockchain consultant"
            description={["Design and develop blockchain oriented solutions for companies", "Create and give trainings around blockchain technology"]}
            date="August 2024 - November 2024"
            loc="Freelance"
            images={[hyperledger]}

            />
        </div>

    </div>
}

interface BoxProps {
    title : string
    description: string[]
    date: string 
    loc: string
    images: any
}


const BoxAbout = ({ title, description, date, loc, images }: BoxProps) => {
    const [hovered, setHovered] = useState(false);
    const [showImages, setShowImages] = useState(false);
  
    return (
      <div
        className={`about-box ${hovered ? "hovered" : ""}`}
        onMouseEnter={() => {
          setHovered(true);
          setTimeout(() => setShowImages(true), 300); 
        }}
        onMouseLeave={() => {
          setShowImages(false); 
          setTimeout(() => setHovered(false), 300); 
        }}
      >
        <div className="color-overlay"></div>
  

        <div className={`content ${showImages ? "hide-text" : "show-text"}`}>
          <span className="about-title">{title}</span>
          {description.map((elm, index) => (
            <li className="about-desc" key={index}>
              {elm}
            </li>
          ))}
          <div className="about-bot">
            <span>{date}</span>
            <span>📍{loc}</span>
          </div>
        </div>
        <div className={`image-container ${showImages ? "show-images" : "hide-images"}`}>
          {images.map((src: any, index: number) => (
            <img src={src} alt={`Image ${index + 1}`} className="about-image" key={index} />
          ))}
        </div>
      </div>
    );
  };
  
  

export default About