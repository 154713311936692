import React from "react";
import "./Projects.css"
export const Projects = () => {
    return <div className="projects-container">
        <div className="project-box">
            <span className="project-title">
                Coming soon...
            </span>
        </div>
    </div>
}

export default Projects