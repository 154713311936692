import React, { useEffect, useState } from "react"
import me from "../../assets/me.png"
import "./Home.css"
import react from "../../assets/react.png"
import solidity from "../../assets/solidity.png"
import sql from "../../assets/sql.png"
import node from "../../assets/node.png"
import hyperledger from "../../assets/hyperledger.svg"
import python from "../../assets/python.png"
import typescript from "../../assets/Typescript.png"
import docker from "../../assets/docker.png"
import aws from "../../assets/aws.png"
import cs from "../../assets/cs.png"
import gh from "../../assets/gh.png"
import lk from "../../assets/linked.png"

export const Home = () => {
    const skills = [
        { image: react, title: "React.JS" },
        { image: node, title: "Node.JS" },
        { image: solidity, title: "Solidity" },
        { image: typescript, title: "TypeScript" },
        { image: python, title: "Python" },
        { image: sql, title: "SQL" },
        { image: hyperledger, title: "Hyperledger" },
        { image: docker, title: "Docker" },
        { image: aws, title: "AWS" },
        { image: cs, title: "C#" },

      ];
    
      const [currentIndex, setCurrentIndex] = useState(0);
      const [isVisible, setIsVisible] = useState(true);
    
      const skillsPerGroup = 3;
    
      useEffect(() => {
        const interval = setInterval(() => {
          setIsVisible(false); // Déclenche l'animation de disparition
          setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + skillsPerGroup) % skills.length); // Passe au groupe suivant
            setIsVisible(true); // Ré-affiche les nouvelles skill boxes
          }, 500); // Délai correspondant à la durée de l'animation
        }, 5000); // Change toutes les 3 secondes
    
        return () => clearInterval(interval);
      }, [skills.length]);
    
      const visibleSkills = skills.slice(
        currentIndex,
        currentIndex + skillsPerGroup
      );
    
      if (visibleSkills.length < skillsPerGroup) {
        visibleSkills.push(...skills.slice(0, skillsPerGroup - visibleSkills.length));
      }
    return <div className="mobile-home-background">
        <img src={me} style={{maxWidth: "50vw", marginTop: "5vh"}} />
        <div className="text-job">
            <span style={{fontSize: "2em", fontWeight: "bold", marginTop: "5vh"}}>
                Hello, I'm Hugo
            </span>
            <div className="job-title">
                <div className="line-job"/>
                <span>
                    Full Stack Developer
                </span>
            </div>
        </div>
        <div className="mobile-socials">
            <a target="_blank" href="https://github.com/hugo-heer" className="social-link-mobile">
                <img src={gh} style={{maxWidth: "10vw"}}/>
            </a>
            <a target="_blank" href="https://www.linkedin.com/in/hugo-heer-b29a0419b/" className="social-link-mobile">
                <img src={lk} style={{maxWidth: "10vw"}}/>
            </a>
        </div>
        <div className={`skill-boxes ${isVisible ? "fade-in" : "fade-out"}`}>
          {visibleSkills.map((skill, index) => (
            <SkillBox key={index} image={skill.image} title={skill.title} />
          ))}
        </div>
    </div>
}

interface SkillBoxProps {
    image: any
    title: string
}

const SkillBox = ({image, title}:SkillBoxProps) => {
    return <div className="skill-box-mobile">
        <div style={{height: "60%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img src={image} style={{maxWidth: "10vw"}}/>
        </div>
        <span style={{marginTop: "10px", fontWeight: "bold", fontSize: "0.7em"}}>
            {title}
        </span>
    </div>
}

export default Home