import React, { useEffect, useState } from "react"

import "./Home.css"
import me from "../../assets/me.png"
import react from "../../assets/react.png"
import solidity from "../../assets/solidity.png"
import sql from "../../assets/sql.png"
import node from "../../assets/node.png"
import hyperledger from "../../assets/hyperledger.svg"
import python from "../../assets/python.png"
import typescript from "../../assets/Typescript.png"
import docker from "../../assets/docker.png"
import aws from "../../assets/aws.png"
import cs from "../../assets/cs.png"
import gh from "../../assets/gh.png"
import lk from "../../assets/linked.png"

export const Home = () => {
    const skills = [
        { image: react, title: "React.JS" },
        { image: node, title: "Node.JS" },
        { image: solidity, title: "Solidity" },
        { image: typescript, title: "TypeScript" },
        { image: python, title: "Python" },
        { image: sql, title: "SQL" },
        { image: hyperledger, title: "Hyperledger" },
        { image: docker, title: "Docker" },
        { image: aws, title: "AWS" },
        { image: cs, title: "C#" },

      ];
    
      const [currentIndex, setCurrentIndex] = useState(0);
      const [isVisible, setIsVisible] = useState(true);
    
      const skillsPerGroup = 3;
    
      useEffect(() => {
        const interval = setInterval(() => {
          setIsVisible(false); // Déclenche l'animation de disparition
          setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + skillsPerGroup) % skills.length); // Passe au groupe suivant
            setIsVisible(true); // Ré-affiche les nouvelles skill boxes
          }, 500); // Délai correspondant à la durée de l'animation
        }, 5000); // Change toutes les 3 secondes
    
        return () => clearInterval(interval);
      }, [skills.length]);
    
      const visibleSkills = skills.slice(
        currentIndex,
        currentIndex + skillsPerGroup
      );
    
      if (visibleSkills.length < skillsPerGroup) {
        visibleSkills.push(...skills.slice(0, skillsPerGroup - visibleSkills.length));
      }

      const handleDownload = () => {
        const pdfPath = "assets/cv.pdf"; 
        const link = document.createElement("a");
        link.href = pdfPath;
        link.download = "cv.pdf"; 
        link.click();
      };
      
    return <div className="home-background">
        <div className="home-left">
            <span style={{fontSize: "2.5em", fontWeight: "bold", marginTop: "80px"}}>
                Hello, I'm Hugo
            </span>
            <div className="home-left-title">
                <div style={{width: "60px", borderBottom: "2px solid black", marginRight: "10px"}}/>
                <span>
                    Full Stack Developer
                </span>

            </div>
            <span style={{marginTop: "10px", fontStyle: "italic"}}>
                I'm a Full Stack developer specialized in blockchain technology. 
            </span>
            <span style={{marginTop: "10px"}}>
            📍 France
            </span>
            <div className="infos-buttons">

                <a target="_blank" href="https://github.com/hugo-heer" className="socials">
                    <img src={gh} style={{maxWidth: "30px"}}/>
                </a>
                <a target="_blank" href="https://www.linkedin.com/in/hugo-heer-b29a0419b/" className="socials" >
                    <img src={lk} style={{maxWidth: "30px"}}/>
                </a>
                
                <button onClick={handleDownload} className="button-info" style={{marginLeft: "20px"}}>
                    Download CV
                </button>
            </div>
            <div className={`skill-boxes ${isVisible ? "fade-in" : "fade-out"}`}>
          {visibleSkills.map((skill, index) => (
            <SkillBox key={index} image={skill.image} title={skill.title} />
          ))}
        </div>
        </div>
        <img src={me}/>
    </div>
}

interface SkillBoxProps {
    image: any
    title: string
}

const SkillBox = ({image, title}:SkillBoxProps) => {
    return <div className="skill-box">
        <div style={{height: "60%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img src={image} style={{maxWidth: "50px"}}/>
        </div>
        <span style={{marginTop: "10px", fontWeight: "bold"}}>
            {title}
        </span>
    </div>
}

export default Home